<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-lg">
          <div
            class="d-flex align-items-center justify-content-between w-100 mt-3"
          >
            <img
              class="esa-logo"
              src="https://www.extendedstayamerica.com/application/themes/esa/images/esa-ps-logo.png"
            />
            <p class="tech-support m-0">
              Tech support: {{ this.supportPhone }}
            </p>
          </div>
          <form
            class="d-flex align-items-center justify-content-start flex-column"
            ref="handleSubmit"
            @submit.prevent="handleSubmit"
            method="post"
            novalidate
          >
            <p class="welcome w-100 mt-5 text-left">
              Welcome! Let's get you connected.
            </p>
            <p class="complete w-100 text-left mb-0 mt-4">
              Please complete the below form:
            </p>
            <div
              class="w-100"
              :class="{ 'error-field': v$.firstName.$errors.length }"
            >
              <b-form-input
                type="text"
                id="firstName"
                class="text-input input form-control"
                v-model="firstName"
                placeholder="First Name"
                required
              />
              <div
                class="input-errors"
                v-for="error of v$.firstName.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div
              class="w-100"
              :class="{ 'error-field': v$.lastName.$errors.length }"
            >
              <b-form-input
                type="text"
                id="lastName"
                class="text-input input form-control"
                v-model="lastName"
                placeholder="Last Name"
                required
              />
              <div
                class="input-errors"
                v-for="error of v$.lastName.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div
              class="w-100"
              :class="{ 'error-field': v$.email.$errors.length }"
            >
              <b-form-input
                type="email"
                id="email"
                v-model="email"
                class="text-input input form-control"
                placeholder="Email Address"
                required
              />
              <div
                class="input-errors"
                v-for="error of v$.email.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div
              class="w-100"
              :class="{ 'error-field': v$.roomNumber.$errors.length }"
            >
              <b-form-input
                type="text"
                id="roomNumber"
                class="number-input input form-control"
                v-model="roomNumber"
                placeholder="Room Number"
                minlength="3" 
                maxlength="4"
                :formatter="formatterRoomNum"
                required
              />
              <div
                class="input-errors"
                v-for="error of v$.roomNumber.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div
              id="error"
              ref="isError"
              :v-if="isError"
              class="error-msg w-100"
            >
              {{ this.isError }}
            </div>
              <div class="terms-of-service d-flex align-items-center flex-column justify-content-start"
              >
                <div
                  class="mt-4"
                >
                <p>
                      By using this WiFi network you agree to the
                      <b-link
                        v-on:click="isHidden = !isHidden"
                        class="service"
                        v-b-modal.modal-scrollable
                        >terms of service.
                      </b-link>
                    </p>
                    <TermsOfServiceComponent
                      :isHidden="this.isHidden"
                    ></TermsOfServiceComponent>
                </div>
                <input
                  :disabled="
                    email == '' &&
                    roomNumber == '' &&
                    firstName == '' &&
                    lastName == '' &&
                    isError !== ''
                  "
                  :class="
                    email !== '' &&
                    roomNumber !== '' &&
                    firstName !== '' &&
                    lastName !== ''
                      ? 'button-wifi-options-login'
                      : 'button-disabled'
                  "
                  class="mt-5 mb-5 px-5 py-2"
                  type="submit"
                  id="submit"
                  value="Connect"
                />
                <router-link
                  id="vendor_associate"
                  class="vendor mt-5"
                  @click.native="handleVendorAssociate"
                  to="/vendor-associate/"
                  >Vendor and associate access</router-link
                >
              </div>
              <div class="footer">
              <footer>
                  <p class="copyright text-center">
                    &#169; 2020-2023 Extended Stay America | All rights reserved.
                  </p>
                </footer>
            </div>
          </form>
        </div>
        <div class="col-lg esa-iframe-login mt-3">
          <iframe
            class="inside-iframe"
            :src="this.iFrameUrl"
            name="page"
            title="ESA-Iframe"
            scrolling="no"
          >
            <p>Your browser does not support iframes.</p>
          </iframe>
        </div>
        <p class="copyright-mobile">
          &#169; 2020-2023 Extended Stay America | All rights reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { GuestWifiService, HelperService  } from "../services";
import TermsOfServiceComponent from "../components/TermsOfServiceComponent.vue";
import configService from "@/services/ConfigService";

const CONFIG = configService.getConfig();

import { useVuelidate } from "@vuelidate/core";
import { loginValidators } from "../validators/loginValidators";

export default Vue.extend({
  name: "LoginComponent",
  components: {
    TermsOfServiceComponent,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      firstName: loginValidators.firstName,
      lastName: loginValidators.lastName,
      email: loginValidators.email,
      roomNumber: loginValidators.roomNumber,
    };
  },
  data: function () {
    return {
      macAddress: "",
      isError: "",
      email: "",
      roomNumber: "",
      firstName: "",
      lastName: "",
      expiryTime: "432000", //this.expiryTime, /* 5 days expressed in seconds */
      className: "free", //this.className,
      isErrorMacAddress: this.isErrorMacAddress,
      isErrorNseId: this.isErrorNseId,
      isHidden: false,
      iFrameUrl: `${CONFIG.VUE_APP_IFRAME_URL}?mid=WIFI`,
      supportPhone: "",
    };
  },
  async mounted() {
    try {
      const gatewayUrl =
        this.$router.history.current.query &&
        this.$router.history.current.query.UURL;
      if (gatewayUrl) {
        var url = new URL(gatewayUrl);
        const response = await GuestWifiService.getHotelByIPAddress({
          ip_address: url.hostname,
        });
        const hotel = response?.data?.hotel;
        this.supportPhone =
        HelperService.formatPhoneNumber(hotel?.support_phone) ?? hotel?.support_phone;
        localStorage.setItem("supportPhone", this.supportPhone);
      } else {
        this.supportPhone = localStorage.getItem("supportPhone");
      }
    } catch (error) {
      console.warn(error);
    } finally {
      this.isTableLoading = false;
    }
  },
  methods: {
    formatterRoomNum(value, event) {
      if (!value || /^\d+$/.test(value)) {
        return value
      } else {
        return this[event.target.id]
      }
    },
    isValidMACAddress: function (macAddress) {
      const validatedMacAddress = new RegExp(
        "^([0-9A-Fa-f]{2}){5}([0-9A-Fa-f]{2})$"
      );
      if (!macAddress || macAddress == null) {
        this.isErrorMacAddress = "Missing device information";
      } else if (macAddress !== "" && validatedMacAddress.test(macAddress)) {
        this.isErrorMacAddress = "";
      } else {
        this.isErrorMacAddress = "Invalid Mac Address. ";
      }
      return macAddress, this.isErrorMacAddress;
    },
    isValidNSEId: function (nseId) {
      if (!nseId || nseId == null) {
        this.isErrorNseId = "Missing device information";
      } else if (nseId !== "") {
        this.isErrorNseId = "";
      } else {
        this.isErrorNseId = "Invalid Mac Address. ";
      }
      return nseId, this.isErrorNseId;
    },
    /* reset */
    handleReset: function () {
      this.$refs.handleSubmit.reset();
      this.isError = "";
    },
    handleStateTransfer: function (input, URL) {
      this.$root.$emit("updateBuyUp", { payload: { input } }, URL);
    },
    handleVendorAssociate: function () {
      let macAddress;
      let nseId;
      let gatewayUrl;
      macAddress =
        this.$router.history.current.query &&
        this.$router.history.current.query.MA;
      nseId =
        this.$router.history.current.query &&
        this.$router.history.current.query.UI;
      gatewayUrl =
        this.$router.history.current.query &&
        this.$router.history.current.query.UURL;

      const input = {
        room_number: this.roomNumber,
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        expiry_time_in_seconds: this.expiry_time_in_seconds,
        class_name: this.className,
        nse_id: nseId,
        mac_address: macAddress,
        gateway_url: gatewayUrl,
      };
      this.handleStateTransfer(
        { ...input },
        this.$router.history.current.query
          ? this.$router.history.current.query
          : ""
      );
    },
    /* submit */
    handleSubmit: async function () {
      const isFormValid = await this.v$.$validate();
      if (isFormValid) {
        try {
          var result;
          var macAddress;
          var nseId;
          var gatewayUrl;
          let errorMessageMacAddress;
          let errorMessageNseId;
          macAddress =
            this.$router.history.current.query &&
            this.$router.history.current.query.MA;
          errorMessageMacAddress = this.isValidMACAddress(macAddress);
          nseId =
            this.$router.history.current.query &&
            this.$router.history.current.query.UI;
          errorMessageNseId = this.isValidNSEId(nseId);
          gatewayUrl =
            this.$router.history.current.query &&
            this.$router.history.current.query.UURL;

          if (errorMessageMacAddress !== "") {
            this.isError = errorMessageMacAddress;
            return;
          }
          if (errorMessageNseId !== "") {
            this.isError = errorMessageNseId;
            return;
          }

          const input = {
            room_number: this.roomNumber,
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            expiry_time_in_seconds: this.expiry_time_in_seconds,
            class_name: this.className,
            nse_id: nseId,
            mac_address: macAddress,
            gateway_url: gatewayUrl,
          };

          result = await GuestWifiService.provideGuestWifi(input);
          const output = result.data;
          if (output.status === "OK") {
            this.handleStateTransfer({ ...input, ...output });
            if (output.has_enhanced_wifi) {
              this.$router.push({
                path: "/enhanced-wifi",
              });
            } else {
              this.$router.push({
                path: "/WifiOptions",
              });
            }
            this.handleReset();
          } else {
            result = await GuestWifiService.provideGuestWifi(input);
            const output = result.data;
            this.handleStateTransfer({ ...input, ...output });
            if (!this.isError && output.status === "OK") {
              this.$router.push({
                path: "/WifiOptions",
              });
              this.handleReset();
            } else if (output.status === "OK") {
              this.$router.push({
                path: "/WifiOptions",
              });
            } else {
              const error_message =
                output.description || output.status_description;
              if (error_message) {
                this.isError = error_message;
              } else {
                this.isError = "An error occurred while enabling wifi access!";
                console.log(`error is ", ${output}`);
              }
            }
          }
        } catch (error) {
          const error_response = error.response.data;
          console.log(`error_response is ", ${error_response}`);
          if (error_response?.description) {
            this.isError = error_response.description;
          } else {
            this.isError = `There was an error submitting the form!`;
            console.log(`error is ", ${error}`);
          }
        } 
      }
      return;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../styles/login.scss";
</style>
