import {http} from "./HttpCommon";
import {GuestWifiRequest} from "@/models/GuestWifiRequest";
import {AccessCodeRequest} from "@/models/AccessCodeRequest";
import {DeviceRequest} from "@/models/DeviceRequest";
import {HotelRequest} from "@/models/HotelRequest";
import {GuestDetailRequest} from "@/models/GuestDetailRequest";
import {Hotel} from "@/models/Hotel";
import {GuestDetailResponse} from "@/models/GuestDetailResponse";

class GuestWifiService {
  async addMacAddress(deviceRequest: DeviceRequest) {
    if(deviceRequest != null)
    {
      deviceRequest.mac_address = deviceRequest.mac_address?.trim();
    }

    return await http.post(
        `/guests/wifi`,
        JSON.stringify(deviceRequest)
    );
  }

  async provideGuestWifi(guestWifiRequest: GuestWifiRequest) {
    if(guestWifiRequest != null)
    {
      guestWifiRequest.interface_type = guestWifiRequest.interface_type?.trim();
      guestWifiRequest.room_number = guestWifiRequest.room_number?.trim();
      guestWifiRequest.property_code = guestWifiRequest.property_code?.trim();
    }

    return await http.post(
        `/guests/wifi`,
        JSON.stringify(guestWifiRequest)
    );
  }

  async provideGuestBuyUpPlan(guestWifiRequest: GuestWifiRequest) {
    if(guestWifiRequest != null)
    {
      guestWifiRequest.interface_type = guestWifiRequest.interface_type?.trim();
      guestWifiRequest.room_number = guestWifiRequest.room_number?.trim();
      guestWifiRequest.property_code = guestWifiRequest.property_code?.trim();
    }

    return await http.post(
        `/guests/plan`,
        JSON.stringify(guestWifiRequest)
    );
  }

  async fetchBuyUpPlans(guestWifiRequest: GuestWifiRequest) {
    if(guestWifiRequest != null)
    {
      guestWifiRequest.interface_type = guestWifiRequest.interface_type?.trim();
      guestWifiRequest.room_number = guestWifiRequest.room_number?.trim();
      guestWifiRequest.property_code = guestWifiRequest.property_code?.trim();
    }

    return await http.post(
        `/guests/hotels/plans`,
        JSON.stringify(guestWifiRequest)
    );
  }

  async provideGuestAccessCode(accessCodeRequest: AccessCodeRequest) {
    if(accessCodeRequest != null)
    {
      accessCodeRequest.access_code = accessCodeRequest.access_code?.trim();
      accessCodeRequest.mac_address = accessCodeRequest.mac_address?.trim();
    }

    return await http.post(
        `/guests/accesscode`,
        JSON.stringify(accessCodeRequest)
    );
  }

  async getHotelByIPAddress(hotelRequest: HotelRequest) {
    if(hotelRequest != null)
    {
      hotelRequest.ip_address = hotelRequest.ip_address?.trim();
    }

    return await http.post(
        `/guests/hotel`,
        JSON.stringify(hotelRequest)
    );
  }

  async getHotelsList(): Promise<Hotel[]> {
    const result = await http.get("/hotels/list");
    return result.data.hotels;
  }

  async getGuestDetails(guestDetailRequest: GuestDetailRequest): Promise<GuestDetailResponse> {
    if(guestDetailRequest != null)
    {
      guestDetailRequest.last_name = guestDetailRequest.last_name?.trim();
      guestDetailRequest.site_id = guestDetailRequest.site_id?.trim();
      guestDetailRequest.room_number = guestDetailRequest.room_number?.trim();
    }

    const result = await http.post(
      "/guests/details",
      JSON.stringify(guestDetailRequest)
    );
    return result.data;
  }
}

export default new GuestWifiService();
